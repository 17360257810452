import React, { ReactNode, useRef } from "react";
import { Box, Button, Card, CardBody, Text } from "@chakra-ui/react";
import "../styles/myCard.css";
import { ReactComponent as Chevron } from "../assets/chevron-compact-down.svg";

interface IMyCardProps {
  children?: ReactNode | null;
  style?: React.CSSProperties;
  inFocus: boolean;
}

export default function MyCard(props: IMyCardProps) {
  return (
    <div style={props.style} className="card-body">
      <div className="gradient"></div>
      {props.children}
      {!props.inFocus ? (
        <Box
          as="button"
          bg={"var(--primary-600)"}
          height={"3rem"}
          zIndex={9000}
          borderRadius={"0.38rem"}
          color={"var(--gray-100)"}
          fontWeight={"600"}
          fontSize={"1rem"}
          paddingInline={"1.5rem"}
          paddingBlock={"0.5rem"}
          display={"flex"}
          alignItems={"center"}
          gap={"0.5rem"}
          _hover={{ bg: "var(--primary-700)" }}
        >
          Learn More
        </Box>
      ) : (
        <Chevron className="card-arrow" />
      )}
    </div>
  );
}
