import React, { createContext, useContext, useRef } from "react";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import Main from "./pages/Main";
import Impressum from "./components/Impressum";
import config from "./config.json";

function App() {
  return (
    <>
      <Main />
    </>
  );
}

export default App;
