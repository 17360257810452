import {
  Alert,
  AlertIcon,
  Box,
  Button,
  FormControl,
  FormErrorMessage,
  FormHelperText,
  FormLabel,
  Input,
  Textarea,
  useToast,
} from "@chakra-ui/react";
import {
  GroupBase,
  MenuListProps,
  OptionProps,
  Select,
} from "chakra-react-select";
import React, { ChangeEvent, useState } from "react";
import { Field, FieldAttributes, Form, Formik, FormikHelpers } from "formik";
import IContact from "../models/IContact";

export default function ContactForm() {
  const toast = useToast();
  const selectOptions = [
    { value: "Commodities", label: "Commodities" },
    { value: "Products", label: "Products" },
    { value: "Services", label: "Services" },
  ];

  function validateMail(value: string) {
    if (value === "" || value === " ") {
      return "Please provide a valid email adress for us to reply.";
    } else return "";
  }

  function onSubmit(values: IContact, setSubmitting: Function) {
    fetch("https://mikebryl.com:5025/Mail/SendMail", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(values),
    })
      .then((response) => {
        if (response.ok) {
          return response;
        } else {
          toast({
            title: "Something went wrong",
            description:
              "Sorry our service is temporarily unavailable, please contact us via email",
            status: "error",
            duration: 5000,
            isClosable: true,
          });
        }
      })
      .then((data) => {
        setSubmitting(false);
        toast({
          title: "Message sent!",
          description: "Thank you for your message. We will answer shortly.",
          status: "success",
          duration: 5000,
          isClosable: true,
        });
      })
      .catch((error) => {
        toast({
          title: "Something went wrong",
          description:
            "Sorry our service is temporarily unavailable, please contact us via email",
          status: "error",
          duration: 5000,
          isClosable: true,
        });
      });
  }

  function setSelectOptionBackgroundColor(
    state: OptionProps<any, false, GroupBase<any>>
  ) {
    if (state.isFocused) {
      return "var(--gray-100) !important";
    } else if (state.isSelected) {
      return "var(--gray-100) !important";
    } else return "var(--primary-800)";
  }

  function setSelectOptionFontColor(
    state: OptionProps<any, false, GroupBase<any>>
  ) {
    if (state.isFocused) {
      return "var(--gray-800) !important";
    } else if (state.isSelected) {
      return "var(--gray-800) !important";
    } else return "var(--gray-100)";
  }

  return (
    <div className="contact-wrapper">
      <Formik
        initialValues={{
          name: "",
          email: "",
          phone: "",
          interest: "",
          message: "",
          partner: "uac",
        }}
        onSubmit={(
          values: IContact,
          { setSubmitting, resetForm }: FormikHelpers<IContact>
        ) => {
          onSubmit(values, setSubmitting);
          resetForm();
        }}
      >
        <Form className="contact-form">
          <h1 className="contact-title">Contact us</h1>
          <Field name="name">
            {({ field }: FieldAttributes<any>) => (
              <FormControl>
                <FormLabel>Name</FormLabel>
                <Input type="text" focusBorderColor="#FFFFFF" {...field} />
              </FormControl>
            )}
          </Field>
          <Field name="email" validate={validateMail}>
            {({ field, form }: FieldAttributes<any>) => (
              <FormControl isInvalid={form.errors.email && form.touched.email}>
                <FormLabel>Email address</FormLabel>
                <Input type="email" focusBorderColor="#FFFFFF" {...field} />
                <FormErrorMessage>{form.errors.email}</FormErrorMessage>
              </FormControl>
            )}
          </Field>
          <Field name="phone" type="tel">
            {({ field }: FieldAttributes<any>) => (
              <FormControl>
                <FormLabel>Phone number</FormLabel>
                <Input focusBorderColor="#FFFFFF" {...field} />
              </FormControl>
            )}
          </Field>
          <Field name="interest">
            {({ field, form }: FieldAttributes<any>) => (
              <FormControl>
                <FormLabel>What are you interested in?</FormLabel>
                <Select
                  name={field.name}
                  options={selectOptions}
                  placeholder="Please Select"
                  value={
                    selectOptions
                      ? selectOptions.find(
                          (option) => option.value === field.value
                        )
                      : ""
                  }
                  onChange={(option: any) =>
                    form.setFieldValue(field.name, option.value)
                  }
                  focusBorderColor="#FFFFFF"
                  chakraStyles={{
                    placeholder: (provided, state) => ({
                      ...provided,
                      color: "var(--gray-300)",
                    }),
                    dropdownIndicator: (provided, state) => ({
                      ...provided,
                      background: "var(--primary-800)",
                    }),
                    menuList: (provided, state) => ({
                      ...provided,
                      backgroundColor: "var(--primary-800)",

                      border: "1px solid #ffffff",
                    }),
                    option: (provided, state) => ({
                      ...provided,
                      backgroundColor: setSelectOptionBackgroundColor(state),
                      color: setSelectOptionFontColor(state),
                    }),
                  }}
                ></Select>
              </FormControl>
            )}
          </Field>
          <Field name="message">
            {({ field }: FieldAttributes<any>) => (
              <FormControl className="textarea">
                <FormLabel>Your Message</FormLabel>
                <Textarea rows={10} focusBorderColor="#FFFFFF" {...field} />
              </FormControl>
            )}
          </Field>
          <Box
            className="button-submit"
            as="button"
            bg={"var(--primary-600)"}
            zIndex={9000}
            borderRadius={"0.38rem"}
            color={"var(--gray-100)"}
            fontWeight={"600"}
            fontSize={"1rem"}
            paddingInline={"1.5rem"}
            paddingBlock={"0.5rem"}
            display={"flex"}
            justifyContent={"center"}
            gap={"0.5rem"}
            _hover={{ bg: "var(--primary-700)" }}
            type="submit"
          >
            Submit
          </Box>
          {/* <Button className="button-submit" type="submit">
            Submit
          </Button> */}
        </Form>
      </Formik>
    </div>
  );
}
