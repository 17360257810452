import { Text } from "@chakra-ui/react";
import React, { useEffect } from "react";
import "../styles/datenschutz.css";

export default function Datenschutz() {
  useEffect(() => {
    document.querySelector("body")?.scrollTo(0, 0);
  });
  return (
    <div className="page-ds">
      <div className="wrapper-ds">
        <Text fontSize={"x-large"} fontWeight={600}>
          Erklärung zur Informationspflicht
        </Text>
        <Text fontSize={"x-large"} fontWeight={600}>
          Datenschutzerklärung
        </Text>
        <Text fontSize={"md"} fontWeight={500}>
          In folgender Datenschutzerklärung informieren wir Sie über die
          wichtigsten Aspekte der Datenverarbeitung im Rahmen unserer Webseite.
          Wir erheben und verarbeiten personenbezogene Daten nur auf Grundlage
          der gesetzlichen Bestimmungen (Datenschutzgrundverordnung,
          Telekommunikationsgesetz 2003).
        </Text>
        <Text fontSize={"md"} fontWeight={500}>
          Sobald Sie als Benutzer auf unsere Webseite zugreifen oder diese
          besuchen wird Ihre IP-Adresse, Beginn sowie Beginn und Ende der
          Sitzung erfasst. Dies ist technisch bedingt und stellt somit ein
          berechtigtes Interesse iSv Art 6 Abs 1 lit f DSGVO.
        </Text>
        <Text fontSize={"md"} fontWeight={600}>
          Kontakt mit uns
        </Text>
        <Text fontSize={"md"} fontWeight={500}>
          Wenn Sie uns, entweder über unser Kontaktformular auf unserer Webseite
          oder per Email kontaktieren, dann werden die von Ihnen an uns
          übermittelten Daten zwecks Bearbeitung Ihrer Anfrage oder für den Fall
          von weiteren Anschlussfragen für sechs Monate bei uns gespeichert. Es
          erfolgt, ohne Ihre Einwilligung, keine Weitergabe Ihrer übermittelten
          Daten.
        </Text>
        <Text fontSize={"md"} fontWeight={600}>
          Cookies
        </Text>
        <Text fontSize={"md"} fontWeight={500}>
          Unsere Website verwendet so genannte Cookies. Dabei handelt es sich um
          kleine Textdateien, die mit Hilfe des Browsers auf Ihrem Endgerät
          abgelegt werden. Sie richten keinen Schaden an. Wir nutzen Cookies
          dazu, unser Angebot nutzerfreundlich zu gestalten. Einige Cookies
          bleiben auf Ihrem Endgerät gespeichert, bis Sie diese löschen. Sie
          ermöglichen es uns, Ihren Browser beim nächsten Besuch
          wiederzuerkennen. Wenn Sie dies nicht wünschen, so können Sie Ihren
          Browser so einrichten, dass er Sie über das Setzen von Cookies
          informiert und Sie dies nur im Einzelfall erlauben. Bei der
          Deaktivierung von Cookies kann die Funktionalität unserer Website
          eingeschränkt sein.
        </Text>
        <Text fontSize={"md"} fontWeight={600}>
          Google Fonts
        </Text>
        <Text fontSize={"md"} fontWeight={500}>
          Unsere Website verwendet Schriftarten von „Google Fonts“. Der
          Dienstanbieter dieser Funktion ist:
        </Text>
        <Text fontSize={"md"} fontWeight={500}>
          Google Ireland Limited Gordon House, Barrow Street Dublin 4. Ireland,
          Tel: +353 1 543 1000
        </Text>
        <Text fontSize={"md"} fontWeight={500}>
          Beim Aufrufen dieser Webseite lädt Ihr Browser Schriftarten und
          speichert diese in den Cache. Da Sie, als Besucher der Webseite, Daten
          des Dienstanbieters empfangen kann Google unter Umständen Cookies auf
          Ihrem Rechner setzen oder analysieren. Die Nutzung von „Google-Fonts“
          dient der Optimierung unserer Dienstleistung und der einheitlichen
          Darstellung von Inhalten. Dies stellt ein berechtigtes Interesse im
          Sinne von Art. 6 Abs. 1 lit. f DSGVO dar. Weitere Informationen zu
          Google Fonts erhalten Sie unter folgendem Link:
        </Text>
        <Text fontSize={"md"} fontWeight={500}>
          <a href="https://developers.google.com/fonts/faq">
            https://developers.google.com/fonts/faq
          </a>
        </Text>
        <Text fontSize={"md"} fontWeight={500}>
          Weitere Informationen über den Umgang mit Nutzerdaten von Google
          können Sie der Datenschutzerklärung entnehmen:
        </Text>
        <Text fontSize={"md"} fontWeight={500}>
          <a href="https://policies.google.com/privacy?hl=de">
            https://policies.google.com/privacy?hl=de
          </a>
        </Text>
        <Text fontSize={"md"} fontWeight={500}>
          Google verarbeitet die Daten auch in den USA, hat sich jedoch dem
          EU-US Privacy-Shield unterworfen.
        </Text>
        <Text fontSize={"md"} fontWeight={500}>
          <a href="https://www.privacyshield.gov/EU-US-Framework">
            https://www.privacyshield.gov/EU-US-Framework
          </a>
        </Text>
        <Text fontSize={"md"} fontWeight={600}>
          Server-Log Files
        </Text>
        <Text fontSize={"md"} fontWeight={500}>
          Diese Webseite und der damit verbundene Provider erhebt im Zuge der
          Webseitennutzung automatisch Informationen im Rahmen sogenannter
          „Server-Log Files“. Dies betrifft insbesondere:
        </Text>
        <Text fontSize={"md"} fontWeight={500}>
          <ul>
            <li>IP-Adresse oder Hostname</li>
            <li>den verwendeten Browser</li>
            <li>Aufenthaltsdauer auf der Webseite sowie Datum und Uhrzeit</li>
            <li>aufgerufene Seiten der Webseite</li>
            <li>Spracheinstellungen und Betriebssystem</li>
            <li>
              „Leaving-Page“ (auf welcher URL hat der Benutzer die Webseite
              verlassen)
            </li>
            <li> ISP (Internet Service Provider)</li>
          </ul>
        </Text>
        <Text fontSize={"md"} fontWeight={500}>
          Diese erhobenen Informationen werden nicht personenbezogen verarbeitet
          oder mit personenbezogenen Daten in Verbindung gebracht.
        </Text>
        <Text fontSize={"md"} fontWeight={500}>
          Der Webseitenbetreiber behält es sich vor, im Falle von Bekanntwerden
          rechtswidriger Tätigkeiten, diese Daten auszuwerten oder zu
          überprüfen.
        </Text>
        <Text fontSize={"md"} fontWeight={600}>
          Ihre Rechte als Betroffener
        </Text>
        <Text fontSize={"md"} fontWeight={500}>
          Sie als Betroffener haben bezüglich Ihrer Daten, welche bei uns
          gespeichert sind, grundsätzlich ein Recht auf:
        </Text>
        <Text fontSize={"md"} fontWeight={500}>
          <ul>
            <li>Auskunft</li>
            <li>Löschung der Daten</li>
            <li>Berichtigung der Daten</li>
            <li>Übertragbarkeit der Daten</li>
            <li>Widerruf und Widerspruch zur Datenverarbeitung</li>
            <li>Einschränkung</li>
          </ul>
        </Text>
        <Text fontSize={"md"} fontWeight={500}>
          Wenn sie vermuten, dass im Zuge der Verarbeitung Ihrer Daten Verstöße
          gegen das Datenschutzrecht passiert sind, so haben Sie die Möglichkeit
          sich bei uns (ag@uacgmbh.at) oder der Datenschutzbehörde zu
          beschweren.
        </Text>
        <Text fontSize={"md"} fontWeight={600}>
          Sie erreichen uns unter folgenden Kontaktdaten:
        </Text>
        <Text fontSize={"md"} fontWeight={500}>
          Webseitenbetreiber: UAC GmbH
          <br />
          Telefonnummer: +436765541712
          <br />
          E-Mail: ag@uacgmbh.at
        </Text>
        <Text fontSize={"md"} fontWeight={500}>
          Quelle: Datenschutz DSGVO Generator in Kooperation mit Rechtsanwalt
          Wien Scheidung
        </Text>
      </div>
    </div>
  );
}
