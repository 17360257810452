import React, { useEffect, useState } from "react";
import { Link, Text } from "@chakra-ui/react";
import "../styles/impressum.css";

export default function Impressum() {
  useEffect(() => {
    document.querySelector("body")?.scrollTo(0, 0);
  });

  return (
    <div className="page-impressum">
      <div className="wrapper-impressum">
        <div className="impressum-content">
          <Text fontSize={"xl"} fontWeight={"600"}>
            Impressum
          </Text>
          <Text fontSize={"lg"} fontWeight={"500"}>
            UAC GmbH
            <br /> Beatrixgasse 32, 1030 Wien <br /> office@uacgmbh.at
          </Text>
          <Text fontSize={"xl"} fontWeight={"600"}>
            Firmenbuchgericht
          </Text>
          <Text fontSize={"lg"} fontWeight={"500"}>
            Handelsgericht Wien {"(HGW)"} <br />
            FN 328114x <br /> UID: ATU67284039
          </Text>
        </div>
        <div className="impressum-content">
          <Text fontSize={"xl"} fontWeight={"600"}>
            Disclaimer
          </Text>
          <Text fontSize={"lg"} fontWeight={"500"}>
            UAC GmbH übernimmt trotz sorgfältiger Ausarbeitung keinerlei Haftung
            für die Richtigkeit, Vollständigkeit und Aktualität der auf der
            Website enthaltenen Informationen, Irrtümer werden vorbehalten. Der
            Benutzer anerkennt den Gebrauch der Website auf eigene Gefahr,
            sodass niemanden, der an der Erstellung der Informationen beteiligt
            war, eine {"(in)"}
            direkte Haftung für {"(Folge-)"}Schäden trifft bzw. diese bis auf
            das gesetzlich äußerst zulässige Maß beschränkt wird. Infos,
            Beschwerden, Anregungen oder Datenauskünfte zur Website unter
            office@uacgmbh.at Wir bestätigen, dass wir keine sensiblen Daten an
            Dritte weitergeben. Copyright © und für den Inhalt verantwortlich
            zeichnet Firma UAC GmbH, welche Informationen aus eigenen und
            fremden Quellen zur Verfügung stellt. Die entsprechenden Urheber-
            und sonstigen Schutzrechte liegen daher gemäß den inländischen und
            EU-rechtlichen Vorschriften bei der Firma UAC GmbH oder den eigens
            genannten sonstigen Anbietern. Die Vervielfältigung von Texten und
            Daten einschließlich Speicherung und Nutzung auf optischen und
            elektronischen Datenträgern darf nur mit vorheriger schriftlicher
            Zustimmung erfolgen. Die Verwendung von Daten inklusive Einspeisung
            in Online-Dienste, Datenbanken oder Websites durch unberechtigte
            Dritte sowie die Veränderung oder Verfälschung von Informationen ist
            untersagt. Der Download sowie der Ausdruck sind jedoch zum
            persönlichen, nicht kommerziellen Gebrauch gestattet.
          </Text>
        </div>
        <div className="impressum-content">
          <Text fontSize={"xl"} fontWeight={"600"}>
            KONZEPT, DESIGN & TECHNISCHE UMSETZUNG
          </Text>
          <Text fontSize={"lg"} fontWeight={"500"}>
            Michael Brylski
          </Text>
        </div>
        <div className="impressum-content">
          <Text fontSize={"sm"} fontWeight={"500"}></Text>
        </div>
        <div className="impressum-content">
          <Text fontSize={"xl"} fontWeight={"600"}>
            Verwendetes Bildmaterial:
          </Text>
          <Text fontSize={"sm"} fontWeight={"500"}>
            <ul>
              <li>
                <a href="https://unsplash.com/de/fotos/qCB_-fzt35s">
                  https://unsplash.com/de/fotos/qCB_-fzt35s
                </a>
              </li>
              <li>
                <a href="https://unsplash.com/de/fotos/0xiZHNKzYWU">
                  https://unsplash.com/de/fotos/0xiZHNKzYWU
                </a>
              </li>
              <li>
                <a href="https://unsplash.com/de/fotos/5WQJ_ejZ7y8">
                  https://unsplash.com/de/fotos/5WQJ_ejZ7y8
                </a>
              </li>
              <li>
                <a href="https://pixabay.com/de/photos/bier-bierf%C3%A4sser-metall-2345250/">
                  https://pixabay.com/de/photos/bier-bierf%C3%A4sser-metall-2345250/
                </a>
              </li>
              <li>
                <a href="https://unsplash.com/de/fotos/Np4XvGhjr50">
                  https://unsplash.com/de/fotos/Np4XvGhjr50
                </a>
              </li>
              <li>
                <a href="https://www.pexels.com/de-de/foto/hande-landschaft-landwirtschaft-bauernhof-12925600/">
                  https://www.pexels.com/de-de/foto/hande-landschaft-landwirtschaft-bauernhof-12925600/
                </a>
              </li>
              <li>
                <a href="https://unsplash.com/de/fotos/RzQSKQ_vwwI">
                  https://unsplash.com/de/fotos/RzQSKQ_vwwI
                </a>
              </li>
              <li>
                <a href="https://de.freepik.com/fotos-kostenlos/weltdiabetestag-zucker-in-holzschale-auf-dunkler-oberflaeche_10401423.htm#query=cane%20sugar&position=7&from_view=search&track=ais">
                  https://de.freepik.com/fotos-kostenlos/weltdiabetestag-zucker-in-holzschale-auf-dunkler-oberflaeche_10401423.htm#query=cane%20sugar&position=7&from_view=search&track=ais
                </a>
              </li>
              <li>
                <a href="https://smarterpix.com/de/photo/p8163690">
                  https://smarterpix.com/de/photo/p8163690
                </a>
              </li>
              <li>
                <a href="https://unsplash.com/de/fotos/in-scheiben-geschnittene-karotten-und-grunes-gemuse-bRdRUUtbxO0">
                  https://unsplash.com/de/fotos/in-scheiben-geschnittene-karotten-und-grunes-gemuse-bRdRUUtbxO0
                </a>
              </li>
              <li>
                <a href="https://pixabay.com/de/photos/guinness-bier-kann-bierdose-6012898/">
                  https://pixabay.com/de/photos/guinness-bier-kann-bierdose-6012898/
                </a>
              </li>
              <li>
                <a href="https://unsplash.com/de/fotos/cNQ_srUuvHk">
                  https://unsplash.com/de/fotos/cNQ_srUuvHk
                </a>
              </li>
              <li>
                <a href="https://unsplash.com/de/fotos/ycnyotoyk8o">
                  https://unsplash.com/de/fotos/ycnyotoyk8o
                </a>
              </li>
              <li>
                <a href="https://unsplash.com/de/fotos/1cqIcrWFQBI">
                  https://unsplash.com/de/fotos/1cqIcrWFQBI
                </a>
              </li>
              <li>
                <a href="https://de.freepik.com/fotos-kostenlos/lkw-fahrzeug-mit-anhaengern-im-hintergrund_11450952.htm#query=logistic&position=39&from_view=search&track=sph">
                  https://de.freepik.com/fotos-kostenlos/lkw-fahrzeug-mit-anhaengern-im-hintergrund_11450952.htm#query=logistic&position=39&from_view=search&track=sph
                </a>
              </li>
              <li>
                <a href="https://de.freepik.com/fotos-kostenlos/nahaufnahme-der-haende-von-menschen-die-an-computern-arbeiten_19068615.htm#query=Agency%20services&position=12&from_view=search&track=ais">
                  https://de.freepik.com/fotos-kostenlos/nahaufnahme-der-haende-von-menschen-die-an-computern-arbeiten_19068615.htm#query=Agency%20services&position=12&from_view=search&track=ais
                </a>
              </li>
              <li>
                <a href="https://de.freepik.com/fotos-kostenlos/abstraktes-networking-konzept-stillleben-sortiment_16234516.htm#query=Handelsnetzwerk&position=30&from_view=search&track=ais">
                  https://de.freepik.com/fotos-kostenlos/abstraktes-networking-konzept-stillleben-sortiment_16234516.htm#query=Handelsnetzwerk&position=30&from_view=search&track=ais
                </a>
              </li>
              <li>
                <a href="https://unsplash.com/de/fotos/PhYq704ffdA">
                  https://unsplash.com/de/fotos/PhYq704ffdA
                </a>
              </li>
              <li>
                <a href="https://de.freepik.com/fotos-kostenlos/ein-bildschirm-mit-einem-diagramm-darauf-auf-dem-das-wort-finanzen-steht_41595705.htm#query=investition&position=2&from_view=keyword&track=sph">
                  https://de.freepik.com/fotos-kostenlos/ein-bildschirm-mit-einem-diagramm-darauf-auf-dem-das-wort-finanzen-steht_41595705.htm#query=investition&position=2&from_view=keyword&track=sph
                </a>
              </li>
            </ul>
          </Text>
        </div>
      </div>

      {/* {" "}
      <div className="page-1 bg">
        <div className="column-impressum">
          <div className="wrapper">
            <h2>
              United Advanced Commodities
              <br />
              UAC GmbH
            </h2>
            <h3>Offenlegung gemäß Mediengesetz</h3>
            <p>
              Firmensitz:
              <br />
              Beatrixgasse 32
              <br />
              1030 Wien
              <br />
              Firmenbuchnummer: FN 328114 x<br />
              UID-Nummer: ATU67284039
              <br />
              Tätigkeitsbeschreibung:
              <br />
              Consulting, Handel mit Waren aller Art
              <br />
            </p>
            <p>
              Geschäftsführerin:
              <br />
              Frau Sytnik Nataliya
              <br />
              Privatperson alleinvertretungsberechtigt
            </p>
            <p>
              Gesellschafter:
              <br />
              Herr Malyutin Nail A.
              <br />
              Privatperson Anteil: 100,00%
            </p>
          </div>
        </div>

        <div className="column-contact">
          <div className="wrapper">
            <h2>Our website will be available again soon.</h2>
            <p>
              Please feel free to contact us:
              <br />
              Nail: <a href="tel:+43 681 846 597 89">
                +43 681 846 597 89
              </a> // <a href="mailto:mn@uacgmbh.at">mn@uacgmbh.at</a>
              <br />
              Alexander: <a href="tel:+43 676 554 1712">
                +43 676 554 1712
              </a> // <a href="mailto:ag@uacgmbh.at">ag@uacgmbh.at</a>
              <br />
              Valeriya: <a href="tel:+43 664 126 6969">
                +43 664 126 6969
              </a> // <a href="mailto:vm@uacgmbh.at">vm@uacgmbh.at</a>
              <br />
              Nataliya: <a href="tel:+43 676 912 6070">
                +43 676 912 6070
              </a> // <a href="mailto:ns@uacgmbh.at">ns@uacgmbh.at</a>
            </p>
          </div>
        </div>
      </div>{" "} */}
    </div>
  );
}
